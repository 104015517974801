
.home-section {
    position: relative;
    z-index: -1;
    /* background-image: var(--image-gradient), url(../assets/jasonaul1.png); */
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }
  
  .home-content {
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    text-align: left;
  }

  .home-col {
    display: inline-block;
    float: left;
  }
  
  .heading {
    font-size: 2.4em !important;
    padding-left: 43px !important;
  }
  
  .heading-name {
    font-size: 2.5em !important;
    padding-left: 45px !important;
  }
  
  .main-name {
    color: #003af8;
  }
  
  .Typewriter__wrapper {
    font-size: 2.2em !important;
    color: #003af8 !important;
    font-weight: 600 !important;
    padding-left: -10px;
    z-index: -10;
  }
  .Typewriter__cursor {
    font-size: 2.4em !important;
    color: #ff0000 !important;
    z-index: -10;
  }
  
  @media (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 1.4em !important;
      font-weight: 500 !important;
      position: absolute !important;
    }
    .Typewriter__cursor {
      display: none !important;
    }
  }

  .home-image {
    display: inline-block;
    position: absolute;
    margin-left: 25%;
  
    height: 350px;
    border-radius: 50%;
    
  }

  @media (max-width: 922px) {
    .home-image {
        display: flex;
        justify-content: center;
        margin-top: 325px;
        z-index: -100;
    }
  }

  .type-h1{
    margin-top: -20px;
  }

  .type-div {
    margin-top: 100px;
  }