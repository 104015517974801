label {
    color: black;
    font-size: 24px;
    text-align: center;
    margin-bottom: .5rem;
}

form {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}


form input, form textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: white;
}

.popuph3 {
    text-align: center;
}

.popupp {
    margin-top: 25px;
    margin-bottom: 25px;
}