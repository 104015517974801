.header-nav {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    
    z-index: 9999;
    
}

.nav-name{
    color: black;
  text-decoration: none;
  margin-top: -15px;
  
}

.nav-linky {
    color: black;
  }

.nav-menu {
    display: flex;
    list-style: none;
    
}

.nav-menu li {
    padding: 0 1rem;
    
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
}

.hamburger {
    display: none;
}

@media only screen and (max-width: 1040px) {
    .nav-menu {

        display: none;

    }

    .nav-menu.active{
        display: block;
        top: 70px;
        background: #ffffff;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100vh;
        position: absolute;

        left: 0;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 3rem;
    }

    .hamburger {
        display: initial;
    }
}