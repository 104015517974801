
.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
    display: inline-block;
  }
  
  .home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
  }
  
  .home-about-body {
    padding-top: 50px;
    margin-left: 100px;
    margin-right: 125px;
    font-size: 1.2em !important;
    text-align: left;
  }
  
  .home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }
  
  .home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
  }
  
  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
  }
  
  .home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }
  
  .home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
  }
  
  .home-social-icons:hover {
    color: #87209e;
    box-shadow: 0 0 5px #87209e;
    text-shadow: 0 0 2px #87209e;
  }
  
  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
  }

  .boldthings {
    color: #003af8;
    font-weight: 700;
  }
  
  .icon-colour {
    color: black !important;
  }

  .end-img {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    max-height: 200px;
  }

  

  @media (max-width: 922px) {
    .h1-screen {
        margin-top: 200px;
    }
  }