
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  
}

.skillset {
  background: #f1f5f8
}

.button-about {
  display: block;
  margin-top: 25px;
  /* background-color: blue;
  color: white; */
  font-size: larger;
  text-align: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.resume {
  background: blue;
  color: white;
  border-radius: 5%;
  padding: 10px;
}

.contact-h1 {
  padding-top: 120px;
  text-align: center;
}

.contact-p {
  text-align: center;
  padding-top: 10px;
}



.about-title {
  padding-top: 100px;
  text-align: center;
}

.about-text {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
}

.nav-linky {
  color: black;
}

body {
  background: #f1f5f8;
}

h1, h4, p, a {
  color: black;
  text-decoration: none;
  margin-top: 30px;
}

.skills-head {
 text-align: center;
}

ul {
  list-style-type: none;
}

/* BUTTON CSS SETTINGS */

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #0055ff;
  color: white;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: .2rem;
}

.btn-light {
  background: transparent;
  color: black;
  
}

.btn:hover{
  background: #00cdf2;
  color: white;
  transition: 0.5s;
}


/* Icons */

.skillset {
  padding-top: 110px;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(0, 38, 255, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(12, 12, 251, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(2, 32, 255, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}


